import * as React from "react";
import { Spinner } from "reactstrap";

const SpinnerComp = () => {
  return (
    <>
      <Spinner className="spinner-inner" type="grow" />
    </>
  );
};

export default SpinnerComp;
