import * as React from 'react'
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import './confirmationPopup.scss'

interface IConfirmationPopupProps {
  openConfirmation: boolean
  confirmation: () => void
  submitRequest: () => void
  walletAddress: string
}
const ConfirmationPopup: React.FunctionComponent<
  IConfirmationPopupProps
> = ({
  openConfirmation,
  confirmation,
  submitRequest,
  walletAddress
}) => {
  return (
    <>
      <div className="modal-div">
        <Modal
          isOpen={openConfirmation}
          style={{ position: 'relative' }}
        >
          <ModalHeader style={{ borderBottom: '0px', padding: '0' }}>
            <div className="d-flex title-div">
              <p className="modal-title">
                Confirm the wallet address
              </p>
              <FontAwesomeIcon
                icon={faCircleXmark}
                className="icon"
                onClick={confirmation}
              />
            </div>
          </ModalHeader>
          <ModalBody
            style={{ padding: '0', margin: '30px 0px 30px 0px' }}
          >
            <textarea
              value={walletAddress}
              disabled
              placeholder="0xc87755A00495D693C874b4B6038808c044A4385A"
              className="text-area"
              rows={5}
            >
              {walletAddress}
            </textarea>
          </ModalBody>
          <Button
            className="confirm-btn"
            onClick={() => {
              submitRequest()
            }}
          >
            Confirm
          </Button>
        </Modal>
      </div>
    </>
  )
}

export default ConfirmationPopup
