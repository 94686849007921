import * as React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import { getImageUrl } from '../../utils/handleAmazonS3Image'

interface ITransferedFailedProps {
  transferFailed: any
  setTransferFailed: any
  setOpenConfirmation: any
  title: string
  subContent: any
  onClickActionText: string
  setErrorPopupContent: React.Dispatch<React.SetStateAction<boolean>>
}
// @ts-ignore
const TransferedFailed: React.FunctionComponent<
  ITransferedFailedProps
> = ({
  setErrorPopupContent,
  title,
  subContent,
  transferFailed,
  onClickActionText,
  setTransferFailed,
  setOpenConfirmation
}) => {
  const notranfer = getImageUrl('no-tranfer.png')

  return (
    <>
      <div className="modal-div">
        <Modal
          // @ts-ignore
          isOpen={transferFailed}
          backdropClassName={'test'}
        >
          <ModalHeader style={{ borderBottom: '0px', padding: '0' }}>
            <div className="header-div">
              <FontAwesomeIcon
                icon={faCircleXmark}
                className="icon"
                onClick={() => {
                  setTransferFailed(false)
                  setOpenConfirmation(false)
                  setErrorPopupContent(false)
                }}
              />
            </div>
          </ModalHeader>
          <ModalBody
            style={{ padding: '0', margin: '17px 0px 0px 0px' }}
          >
            <div className="success-img">
              <img
                src={notranfer}
                alt="No Transfer"
                width="140px"
                height="140px"
              />
            </div>
            <div className="title">
              <p>{title}</p>
            </div>
            <div className="sub-title-div">
              <p className="sub-title">{subContent}</p>
            </div>
            <p
              style={{
                color: '#007DE4',
                textAlign: 'center',
                fontSize: '13px',
                cursor: 'pointer'
              }}
              onClick={() => {
                setTransferFailed(false)
                setOpenConfirmation(false)
                setErrorPopupContent(false)
              }}
            >
              {onClickActionText}
            </p>
          </ModalBody>
        </Modal>
      </div>
    </>
  )
}

export default TransferedFailed
