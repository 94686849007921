import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import "./receivedTokens.scss";

interface IReceivedTokensProps {
  token: any;
  key: number;
}
// @ts-ignore
const ReceivedTokens: React.FunctionComponent<IReceivedTokensProps> = ({
  token,
}) => {
  return (
    <>
      <div className="received-token-div d-flex">
        <div className="token">{token}</div>
        <div className="icon">
          <FontAwesomeIcon icon={faCircleCheck} />
        </div>
      </div>
    </>
  );
};

export default ReceivedTokens;
