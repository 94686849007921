import { createSlice } from "@reduxjs/toolkit";

interface connectedWithMetaMaskState {
  connectedWithMetaMask: any;
}

const initialState = {
  connectedWithMetaMask: "",
} as connectedWithMetaMaskState;

const metamaskConnectedSlice = createSlice({
  name: "connectedWithMetaMask",
  initialState,
  reducers: {
    fetchIsConnectedWithMetamask: (state, { payload }) => {
      state.connectedWithMetaMask = payload;
    },

    setWalletAddressStore: (state, { payload }) => {
      state.connectedWithMetaMask = payload;
    },
  },
});

export const { fetchIsConnectedWithMetamask, setWalletAddressStore } =
  metamaskConnectedSlice.actions;
export default metamaskConnectedSlice.reducer;
