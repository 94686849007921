import { createSlice} from '@reduxjs/toolkit'

interface WalletAddressState {
  walletAddress: any
}

const initialState = { 
  walletAddress: [] 
} as WalletAddressState

const walletAddressSlice = createSlice({
  name: 'walletAddress',
  initialState,
  reducers: {
    fetchWalletAddress: (state, {payload}) => {
      state.walletAddress = payload
    },
  },
})

export const { fetchWalletAddress } = walletAddressSlice.actions
export default walletAddressSlice.reducer