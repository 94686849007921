import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Button } from 'reactstrap'
import { useDispatch } from 'react-redux'
import './searchBar.scss'
import { setWalletAddressStore } from '../../store/connectedWithMetamask/slicer'

interface ISearchBarProps {
  walletAddress: string
  sendRequestToken: any
  walletAddressValidation: boolean
}

const SearchBar: React.FunctionComponent<ISearchBarProps> = ({
  walletAddress,
  walletAddressValidation,
  sendRequestToken
}) => {
  const { handleSubmit, handleChange, setFieldValue } =
    sendRequestToken

  const dispatch = useDispatch()

  const isConnected = useSelector(
    (state: any) =>
      state.isConnectedWithMetaMask.connectedWithMetaMask
  )

  useEffect(() => {
    if (localStorage.getItem('walletAddress')) {
      dispatch(
        setWalletAddressStore(localStorage.getItem('walletAddress'))
      )
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (isConnected !== 'undefined') {
      setFieldValue('search', isConnected)
    }
  }, [isConnected, setFieldValue])

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div
          className={` d-flex ${
            (sendRequestToken?.touched?.search &&
              sendRequestToken?.errors?.search) ||
            walletAddressValidation
              ? 'send-token-sec-class-two'
              : 'send-token-sec'
          } `}
        >
          <div className="input-sec">
            <input
              autoComplete="OFF"
              placeholder="Enter your wallet address (0xxxxxxxxxxxxxxxxxxxxxxxxxxx)"
              value={walletAddress}
              name="search"
              maxLength={70}
              onChange={handleChange}
            />
          </div>
          <div className="btn-div">
            {/* @ts-ignore */}
            <Button
              className="send-token-btn"
              onClick={() => handleSubmit()}
            >
              Send me tokens
            </Button>
          </div>
        </div>
        {sendRequestToken?.touched?.search &&
          sendRequestToken?.errors?.search && (
            <p className="error_msg">
              {sendRequestToken?.errors?.search}
            </p>
          )}
        {walletAddressValidation && (
          <p className="error_msg">
            Please Enter a Valid Wallet Address
          </p>
        )}
      </form>
    </>
  )
}

export default SearchBar
