import React from 'react'
import { toastSuccess } from '../../utils/commonFunctions'
import './header.scss'
import { useDispatch } from 'react-redux'
import { fetchWalletAddress } from '../../store/metamaskWalletAddress/slicer'
import { browserName } from 'react-device-detect'
import { fetchIsConnectedWithMetamask } from '../../store/connectedWithMetamask/slicer'
import { getImageUrl } from '../../utils/handleAmazonS3Image'

interface IHeaderProps {
  errorPopupContent: boolean
  errorPopup: (t: any) => void
}

const networks = {
  edexaUniverse: {
    chainId: `0x${Number(1995).toString(16)}`,
    chainName: 'edeXa Blockchain Testnet',
    nativeCurrency: {
      name: 'EDX',
      symbol: 'EDX',
      decimals: 18
    },
    rpcUrls: ['https://testnet.edexa.com/rpc'],
    blockExplorerUrls: ['https://explorer.testnet.edexa.com/']
  }
}
const logo = getImageUrl('edexa-logo.svg')
// @ts-ignore
const changeNetwork = async ({ networkName }) => {
  try {
    // @ts-ignore
    if (!window?.ethereum) throw new Error('No crypto wallet found')
    // @ts-ignore
    await window?.ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [
        // @ts-ignore
        { ...networks[networkName] }
      ]
    })
  } catch (err: any) {
    console.log(err.stack)
  }
}

// @ts-ignore
const Header: React.FunctionComponent<IHeaderProps> = ({
  errorPopup
}) => {
  const dispatch = useDispatch()

  const handleNetworkSwitch = async (networkName: string) => {
    try {
      // @ts-ignore
      if (window?.ethereum) {
        // @ts-ignore
        window?.ethereum
          .request({ method: 'eth_requestAccounts' })
          .then((result: any) => {
            dispatch(fetchWalletAddress(result[0]))
            dispatch(fetchIsConnectedWithMetamask(result[0]))
            localStorage.setItem('walletAddress', result[0])
          })
          .catch((err: any) => {
            errorPopup(true)
          })
      } else {
        if (browserName === 'Chrome') {
          window.open(
            'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en',
            '_blank' // <- This is what makes it open in a new window.
          )
        } else if (browserName === 'Firefox') {
          window.open(
            'https://addons.mozilla.org/en-US/firefox/addon/ether-metamask/',
            '_blank'
          )
        } else if (browserName === 'Edge') {
          window.open(
            'https://microsoftedge.microsoft.com/addons/detail/metamask/ejbalbakoplchlghecdalmeeeajnimhm?hl=en-US',
            '_blank'
          )
        } else if (browserName === 'Brave') {
          window.open(
            'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn',
            '_blank'
          )
        } else {
          window.open('https://metamask.io/download/', '_blank')
        }
      }
      await changeNetwork({ networkName })
    } catch (error) {
      console.log(error)
    }
  }

  //  commenting code of changing network

  // const networkChanged = (chainId: any) => {
  //   console.log({ chainId });
  // };

  // useEffect(() => {
  //   // @ts-ignore
  //   window?.ethereum?.on("chainChanged", networkChanged);
  //   return () => {
  //     // @ts-ignore
  //     window?.ethereum?.removeListener("chainChanged", networkChanged);
  //   };
  // }, []);

  return (
    <>
      <div className="main d-flex">
        <div
          className="img"
          style={{ padding: '15px 0px 15px 15px' }}
        >
          <img src={logo} alt="logo" width="93px" height="30px" />
        </div>
        <div className="logo-line">|</div>
        <div className="header-text-div logo-titl-div">
          <span className="logo-title" style={{ marginLeft: '20px' }}>
            edeXa{' '}
          </span>
          <span className="logo-title faucet-text">Blockchain</span>
        </div>
        <div className="btn-div">
          <a
            className="send-token-btnn testnet-url"
            href="https://explorer.testnet.edexa.com/"
            target="_blank"
            rel="noreferrer"
          >
            edeXa Testnet
          </a>
        </div>
        <div className="btn-div">
          <p
            className="send-token-btnn"
            id="cmdt_1_1i"
            onClick={() => {
              handleNetworkSwitch('edexaUniverse')
            }}
          >
            Add to Metamask
          </p>
        </div>
        <div
          className="header-text-div support-text-div"
          style={{ cursor: 'pointer' }}
          onClick={() => toastSuccess('Coming Soon')}
        >
          <p className="logo-title support-txt">Support</p>
        </div>
      </div>
    </>
  )
}

export default Header
