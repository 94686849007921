import * as React from "react";
import "./footer.scss";
import { toastSuccess } from "../../utils/commonFunctions";

const Footer = () => {
  return (
    <>
      <div className="footer-div d-flex">
        <div className="footer-year-txt">
          <p className="footer-txt">
            edeXa Blockchain © {new Date().getFullYear()}
          </p>
        </div>
        <div className="d-flex">
          <a
            href="https://edexa.network/about"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
            target="_blank"
          >
            <div>
              <p className="footer-txt">About</p>
            </div>
          </a>
          <div
            onClick={() => toastSuccess("Coming Soon")}
            style={{ cursor: "pointer" }}
          >
            <p className="footer-txt">Help</p>
          </div>
          <a
            href="https://edexa.network/privacy-policy"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
            target="_blank"
          >
            <div>
              <p className="footer-txt">Privacy</p>
            </div>
          </a>
          <a
            href="https://edexa.network/terms-and-conditions"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
            target="_blank"
          >
            <div>
              <p className="footer-txt">Terms</p>
            </div>
          </a>
        </div>
      </div>
    </>
  );
};

export default Footer;
