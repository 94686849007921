import toast from "react-hot-toast";

const toasterPosition = "bottom-left";

export const toastSuccess = (message: string) => {
  toast.success(message, {
    position: toasterPosition,
    className: "toast-info",
    style: {
      color: "#000",
      minWidth: 150,
      padding: 10,
      fontWeight: 500,
      marginBottom: 60,
      border: "1px solid #073E84",
    },
    iconTheme: { primary: "#073E84 ", secondary: "#fff" },
  });
};
