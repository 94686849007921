import * as React from "react";
import { SearchBar } from "../searchBar";
import "./sendTokens.scss";

interface ISendTokensProps {
  walletAddress: string;
  sendRequestToken: any;
  walletAddressValidation: boolean;
}

// @ts-ignore
const SendTokens: React.FunctionComponent<ISendTokensProps> = ({
  walletAddress,
  sendRequestToken,
  walletAddressValidation,
}) => {
  return (
    <>
      <div className="title">
        <div className="banner-img-div">
          <div className="banner-inner-content">
            <p className="cosmos-txt">edeXa Blockchain</p>
            <SearchBar
              walletAddress={walletAddress}
              sendRequestToken={sendRequestToken}
              walletAddressValidation={walletAddressValidation}
            />
            <div className="cosmos-txt-subtitle-sec">
              <p className="cosmos-txt-subtitle">
                Hello intrepid spaceperson! Use this edeXa Blockchain to get
                tokens for the latest edx tokens testnet. Please don't abuse
                this service—the number of available tokens is limited.
              </p>
            </div>
            <div className="cosmos-txt-subtitle-sec">
              <p className="cosmos-txt-subtitle">
                This tool does *NOT* give real $EDX tokens.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SendTokens;
