import React, { useEffect, useState } from 'react'
import { ReceivedTokens } from '../components/receivedTokens'
import { SendTokens } from '../components/sendTokens'
import SuccessConfirmation from '../components/successModel/SuccessConfirmation'
import { TransferedFailed } from '../components/transferFailed'
import './home.scss'
import axios from 'axios'
import { Toaster } from 'react-hot-toast'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { Buffer } from 'buffer'
import { Header } from '../components/header'
import { Footer } from '../components/footer'
import ConfirmationPopup from '../components/confirmationPopup/ConfirmationPopup'

global.Buffer = Buffer
const apiUrl = process.env.REACT_APP_RECEVIED_TOKEN_API
// @ts-ignore
const Home = () => {
  const [walletAddressValidation, setWalletAddressValidation] =
    useState(false)
  var WAValidator = require('wallet-address-validator')
  const [setWalletAddress] = useState()
  const [apiStatusCode, setApiStatusCode] = useState()
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [requestSubmittedPopup, setRequestSubmittedPopup] =
    useState(false)
  const [transferFailed, setTransferFailed] = useState(false)
  const [receivedEdxTokens, setReceivedEdxTokens] = useState()
  const [loading, setLoading] = useState(false)
  const [errorPopupContent, setErrorPopupContent] = useState(false)
  const [message, setMessage] = useState('')

  const sendRequestToken = useFormik({
    initialValues: {
      search: ''
    },
    validationSchema: yup.object().shape({
      search: yup
        .string()
        .max(69, 'Token must be lesser than 70 characters')
        .required('Please Enter Your Wallet Address')
    }),
    onSubmit: (values: any) => {
      if (values?.search && valid) {
        setOpenConfirmation(!openConfirmation)
      } else if (values?.search && !valid) {
        setWalletAddressValidation(true)
      }
    }
  })
  const { values, setFieldValue, resetForm } = sendRequestToken
  const walletAddress: string = values?.search
  var valid = WAValidator.validate(walletAddress, 'ethereum')

  useEffect(() => {
    if (!values?.search) {
      setWalletAddressValidation(false)
    }
  }, [values?.search])

  const listOfToken = () => {
    setLoading(true)
    axios
      .get(`${apiUrl}/faucet?limit=3`)
      .then((res) => {
        setReceivedEdxTokens(res.data)
      })
      .catch((err) => console.log(err))
    setLoading(false)
  }

  useEffect(() => {
    listOfToken()
  }, [])

  const submitRequest = () => {
    setLoading(true)
    axios
      // @ts-ignore
      .post(`${apiUrl}/faucet`, {
        to: values?.search
      })
      .then((res) => {
        setApiStatusCode(res?.data?.status)
        res?.data?.status &&
          res?.data?.status === 202 &&
          setRequestSubmittedPopup(true)
        setLoading(false)
      })
      .catch((err) => {
        if (err?.response?.status === 422) {
          alert('Please add a proper wallet address')
        } else if (err?.response?.status === 429) {
          setMessage(err?.response?.data?.message)
          setTransferFailed(true)
        } else if (err?.response?.status === 500) {
          alert(
            'Something went wrong, please try again after sometime'
          )
        }
        setLoading(false)
      })
  }

  const confirmation = () => {
    setOpenConfirmation(!openConfirmation)
  }

  const errorPopup = (t: any) => {
    setErrorPopupContent(t)
  }


  return (
    <>
      <Header
        errorPopupContent={errorPopupContent}
        errorPopup={errorPopup}
      />
      <SendTokens
        walletAddress={walletAddress}
        sendRequestToken={sendRequestToken}
        walletAddressValidation={walletAddressValidation}
      />

      <p className="received-tokens">
        Recent users who received edX tokens
      </p>
      {/* @ts-ignore */}
      {receivedEdxTokens?.data?.length === 0 && (
        <p className="received-tokens" style={{ color: '#a9acaf' }}>
          No Users Found
        </p>
      )}
      {/* @ts-ignore */}
      {receivedEdxTokens?.data?.map((token, index) => {
        const { to } = token
        return <ReceivedTokens key={index} token={to} />
      })}
      <Footer />
      <ConfirmationPopup
        openConfirmation={openConfirmation}
        walletAddress={walletAddress}
        confirmation={confirmation}
        submitRequest={submitRequest}
      />
      <SuccessConfirmation
        requestSubmittedPopup={requestSubmittedPopup}
        setRequestSubmittedPopup={setRequestSubmittedPopup}
        setOpenConfirmation={setOpenConfirmation}
        setWalletAddress={setWalletAddress}
        setApiStatusCode={setApiStatusCode}
        apiStatusCode={apiStatusCode}
        loading={loading}
        valid={valid}
        walletAddress={walletAddress}
        values={values}
        setFieldValue={setFieldValue}
        resetForm={resetForm}
      />

      <TransferedFailed
        title={
          errorPopupContent
            ? 'Cannot connect with Metamask?'
            : 'Transfered Failed'
        }
        subContent={
          errorPopupContent
            ? 'Either now or sometime ago you would have forcedly closed the metamask, so due to this you will not be able to connect, to reconnect, please click on metamask extension'
            : message
            ? message
            : ''
        }
        onClickActionText={errorPopupContent ? 'Ok' : 'Try Again'}
        transferFailed={errorPopupContent ? true : transferFailed}
        setTransferFailed={setTransferFailed}
        setOpenConfirmation={setOpenConfirmation}
        setErrorPopupContent={setErrorPopupContent}
      />
      <Toaster />
    </>
  )
}

export default Home
