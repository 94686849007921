import * as React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import './SuccessConfirmation.scss'
import { SpinnerComp } from '../spinner'
import { getImageUrl } from '../../utils/handleAmazonS3Image'

const SuccessConfirmation: React.FC<any> = ({
  requestSubmittedPopup,
  setRequestSubmittedPopup,
  setOpenConfirmation,
  setApiStatusCode,
  loading
}) => {
  const success = getImageUrl('success.png')

  return (
    <>
      {loading ? (
        <>
          <SpinnerComp />
        </>
      ) : (
        <div className="modal-div">
          <Modal
            isOpen={requestSubmittedPopup}
            toggle={() => {
              setRequestSubmittedPopup(!requestSubmittedPopup)
              setOpenConfirmation(false)
              setApiStatusCode(null)
            }}
          >
            <ModalHeader
              style={{ borderBottom: '0px', padding: '0' }}
            >
              <div className="header-div">
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  className="icon"
                  onClick={() => {
                    setRequestSubmittedPopup(!requestSubmittedPopup)
                    setOpenConfirmation(false)
                    setApiStatusCode(null)
                  }}
                />
              </div>
            </ModalHeader>
            <ModalBody
              style={{ padding: '0', margin: '17px 0px 0px 0px' }}
            >
              <div className="success-img">
                <img
                  src={success}
                  alt="success"
                  width="140px"
                  height="140px"
                />
              </div>
              <div className="title">
                <p>Request submitted</p>
              </div>
              <div className="sub-title-div">
                <p className="sub-title">
                  The transfer is on the way. Token will be
                  transferred to you in 2-3 minutes.
                </p>
              </div>
            </ModalBody>
          </Modal>
        </div>
      )}
    </>
  )
}

export default SuccessConfirmation
